<template>
  <div class="content" style="
      display: block;
      width: 100%;
      max-width: 1200px;
      padding: 0 20px;
      margin: 20px auto;
      box-sizing: border-box;
    ">
    <p style="margin-top:5px;margin-right:16px;margin-bottom: 5px;margin-left:0;text-align:center"><strong><br> <span
          style="font-size: 14px;"><strong><span style="font-family: 宋体, SimSun;">【巡检GO】</span></strong><strong><span
              style="font-family: 宋体, SimSun;">服务协议</span></strong></span></strong></p>
    <p style="margin-top:5px;margin-right:16px;margin-bottom:5px;margin-left:0;text-align:right"><strong><span
          style="font-size: 14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
    <p style="margin-top:5px;margin-right:16px;margin-bottom:5px;margin-left:0;text-align:right"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">更新日期：</span></strong><span
        style="font-size:14px">2023年6月28日</span></p>
    <p style="margin-top:5px;margin-right:16px;margin-bottom:5px;margin-left:0;text-align:right"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">生效日期：</span></strong><span
        style="font-size:14px">2023年6月28日</span></p>
    <p
      style="margin-top:0;margin-right:16px;margin-bottom:0;margin-left: 0;margin-bottom:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size:14px"><br> <br> </span></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">本网站由铂诺客智能科技(北京)有限公司（以下简称“铂诺客”）运行维护。本网站向企业公众开放，面对建筑及房地产行业从业人员，致力于就建筑及房地产行业各种类型开发建设项目提供全流程中各个阶段所需求的智慧软件在线服务，并通过大量建设项目的实施案例提供相关数据查询、数据统计、数据分析，解决行业痛点问题。
          在使用本网站前，敬请您仔细阅读以下各项使用条款（以下简称“本使用条款”）。您对本网站的使用(包括但不限于对本网站的访问、登录，对本网站内容的浏览和使用)，将被视为您自愿承诺接受本声明的约束。如果您对本使用条款的内容不能接受，您应当立即停止使用本网站并迅速离开。</span></strong>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">欢迎使用【巡检GO】产品及服务！</span></strong></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">提示：在使用【巡检GO】产品及服务之前，请您务必认真阅读并遵守《【巡检GO】用户协议》（以下简称“本协议”）和《【巡检GO】隐私权政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读</span></strong>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序，或您以其他【巡检GO】允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得【巡检GO】对您问询的解答等理由，主张本协议无效或要求撤销本协议</span></strong>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;font-weight:normal">如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称“监护人”）的陪同下阅读和判断是否同意本协议。</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">一、缔约主体</span></strong></p>
    <p
      style="margin-top:3px;margin-right:0;margin-bottom:3px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">&nbsp;&nbsp;
        本协议由通过【巡检GO】网站、移动客户端【巡检GO】应用软件以及其他方式使用【巡检GO】服务的用户（以下简称“用户”或“您”）与铂诺客信息科技有限公司（以下简称“铂诺客”或“我们”）共同缔结。</span></p>
    <p
      style="margin-top:3px;margin-right:0;margin-bottom:3px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">二、协议内容和效力</span></strong></p>
    <p
      style="margin-top:3px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;"
        data-spm-anchor-id="a1zaa.8161610.0.i1.4ff63d4cC0clC8">2.1.本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">三、服务内容</span></strong><br></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><span
          style="font-size: 14px;">3.1.【巡检GO】服务是一项面向广大用户提供的在线协同的电子回执服务。【巡检GO】服务包括【巡检GO】移动客户端、网页端、电脑端（网址为：www.zytsaas.com）以及将【巡检GO】部分功能或服务作为第三方服务集成在第三方硬件、软件后为用户提供的服务。我们会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的服务，则可以同一账号使用其他服务，本协议自动适用于您对所有版本的软件和服务的使用。3.1.【巡检GO】服务是一项面向广大用户提供的在线协同的电子回执服务。【巡检GO】服务包括【巡检GO】移动客户端、网页端、电脑端（网址为：www.zytsaas.com）以及将【巡检GO】部分功能或服务作为第三方服务集成在第三方硬件、软件后为用户提供的服务。我们会不断丰富您使用本服务的终端、形式等，如您已注册使用一种形式的服务，则可以同一账号使用其他服务，本协议自动适用于您对所有版本的软件和服务的使用。</span></span></span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">3.2.本协议不适用于任何其他第三方通过前述网页或【巡检GO】客户端向您提供的服务，诸如通过【巡检GO】开放平台相关数据应用程序开发接口API创建的任何应用、小程序、H5等形式服务（以上统称“第三方服务”）。</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-family: 宋体, SimSun;"><span
            style="font-family: 宋体, SimSun; font-size: 14px;">3.3.铂诺客依据本协议、《【巡检GO】隐私权政策》以及其他适用的规则，许可您对【巡检GO】软件进行不可转让的、非排他性的使用。您可以下载本软件中电子回执的一个副本，用作备份、打印。</span></span></strong>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;"
        data-spm-anchor-id="a1zaa.8161610.0.i2.4ff63d4cC0clC8">3.4.铂诺客有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并在【巡检GO】官网或客户端上公示通知。</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">四、注册及账号管理</span></strong></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><span style="font-size: 14px;">4.1.</span><strong><span
            style="font-size: 14px; font-weight: normal;">您确认，在您完成注册程序或以其他铂诺客允许的方式使用【巡检GO】服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人。若您不具备前述主体资格，请取得您的监护人的同意。如未取得，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且铂诺客有权注销或永久冻结您的账户，并向您及您的监护人索偿。</span></strong></span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><span
          style="font-size: 14px;">4.2.您可以使用您提供或确认的手机号或者以铂诺客允许的其它方式作为账号进行注册,您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息，同时系统将为你自动分配一个【巡检GO】账号，你可以对账号昵称和头像等个人资料进行设置。<strong>您理解并同意，您承诺注册的账号名称、设置的昵称、头像等信息中不得出现违法和不良信息，您不得冒用他人身份信息注册，不得未经许可为他人注册，不得以可能导致其他用户产生误认的方式注册账号或设置账号个人资料，不得使用可能侵犯他人权益的昵称或头像（包括但不限于涉嫌商标侵权、名誉权侵权等），否则铂诺客有权不予注册或停止服务并收回您的</strong></span><strong><span
            style="font-size: 14px; font-weight: normal;">【巡检GO】</span></strong><strong><span
            style="font-size: 14px;">账号，因此产生的损失由您自行承担</span></strong><span style="font-size: 14px;">。</span></span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size: 14px; font-family: 宋体, SimSun;">4.3.您了解并同意，【巡检GO】账号所有权归属于铂诺客，注册完成后，您仅获得账号使用权<span
            style="font-size: 14px; font-weight: normal;">。</span></span></strong><strong><span
          style="font-family: 宋体, SimSun; font-size: 14px; font-weight: normal;">【巡检GO】账号使用权仅归属于初始申请注册人，不得以任何方式转让或提供给他人使用，否则，铂诺客有权立即不经通知注销、冻结或收回该账号，由此带来的数据、信息等被清空、丢失等的损失，您应自行承担。</span></strong>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-family: 宋体, SimSun;"><span style="font-size: 14px;">4.4.</span></span></strong><span
        style="font-family: 宋体, SimSun;"><span style="font-size: 14px;"></span><span
          style="background-color: rgb(255, 255, 255);"><strong><span
              style="font-size: 14px;">在您成功注册后，我们将根据您的身份要素识别您的身份和授权登录。“身份要素”包括但不限于您的账户名称、密码、短信校验码、手机号码、身份证件号码等。您同意基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。<strong>请您务必妥善保管您的终端设备及【巡检GO】账户、密码和/或您的身份要素信息，并对利用该账户和密码/身份信息所进行的一切活动（包括但不限于网上点击同意、网上签署任何协议、以及上传、下载、传输、管理、分享任何数据）负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知铂诺客，且您同意并确认，除法律另有规定外，铂诺客不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得铂诺客的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）</strong></span></strong><span
            style="font-size: 14px;">。</span></span></span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <strong><span
          style="font-size: 14px; font-family: 宋体, SimSun;">4.5.您了解并同意，如您注册【巡检GO】账号后连续超过12个月未登录，铂诺客为优化管理等目的，有权回收该账号。</span></strong>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun; font-size: 14px;">4.6.<strong><span
            style="font-family: 宋体, SimSun; font-weight: normal;">如您注销【巡检GO】或停止使用服务时，请您自行事先处理您账号内的数据并确保账号处于安全状态。</span></strong></span>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><span
        style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">五、服务使用规范</span></strong></span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.1.您充分了解并同意，您应自行对利用服务从事的所有行为及结果承担责任。相应的您应了解，使用【巡检GO】服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">5.2.您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为:</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">5.2.1.制作、上载、传送、存储、分享含有下列内容之一的信息：</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">a)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>反对宪法所确定的基本原则的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">b)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">c)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>损害国家荣誉和利益的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">d)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>煽动民族仇恨、民族歧视、破坏民族团结的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">e)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">f)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">g)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">h)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>丑化、侮辱或者诽谤他人，侵害他人名誉权、肖像权等合法权利的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">i)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">j)<span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">&nbsp;&nbsp;
        </span>含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.2.冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关，或未经他人授权或明确同意，获取冒充对象或他人的【巡检GO】服务的访问权；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.3.请求、收集、索取、滥用或以其他方式获取他人的【巡检GO】账号、密码或其他身份验证凭据，或未经原始用户同意，将其用户信息泄露给任何非原始用户；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">5.2.4.伪造标题或以其他方式操控识别资料，使人误认为该内容为铂诺客或其关联公司所传送；</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.5.将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、传播、分享或下载；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.6.将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权）之内容上载、传送或分享；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.7.干扰或破坏【巡检GO】或【巡检GO】任何部分或功能的正常运行，包括但不限于避开或尝试避开【巡检GO】账号注册/认证机制、【巡检GO】内容保护机制；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.8.通过修改或伪造【巡检GO】运行中的指令、数据，增加、删减、变动【巡检GO】的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.9.通过非铂诺客开发、授权的第三方软件、插件、外挂、系统，登录或使用【巡检GO】软件及服务，或制作、发布、传播上述工具；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.10.自行、授权他人或利用第三方软件对他人访问、使用【巡检GO】及其组件、模块、数据等进行跟踪、查看或操作；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.2.11.违反遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为；</span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">5.2.12.从事任何违反中国法律、法规、规章、政策及规范性文件的行为。</span></p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">5.3.</span></strong><strong><span
            style="font-size: 14px;">您同意并接受，尽管【巡检GO】不会非法或非经授权地访问、使用、改变或披露您在【巡检GO】的用户文件数据，【巡检GO】有权按照法律法规和有关监管机构的规范性文件的规定对您通过【巡检GO】传输、分享的文件数据，进行主动或依举报地审查、监督。若存在以不符合本协议的方式使用【巡检GO】的行为，【巡检GO】有权做出独立判断，且有权在无需事先通知的情况下立即终止提供部分或全部服务。对于通过【巡检GO】散布和传播反动、色情或其他违反国家法律、法规的信息，【巡检GO】的系统记录有可能作为违反法律法规证据。如您进行上述内容在【巡检GO】服务的上载、传播而导致任何第三方提出索赔要求或衍生的任何损害或损失，由您自行承担全部责任。</span></strong></span>
    </p>
    <p
      style="margin-top:0;margin-right:0;margin-bottom:8px;margin-left:0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">5.4.如您作为非利益相关方认为【巡检GO】上存在上述一项或以上内容违法违规，您可以通过【巡检GO】上的“举报”功能进行举报或通过本协议以下第十六条所列的联系方式举报。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">六、服务费用</span></strong><span
          style="font-size: 14px;"></span></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">6.1.我们的服务包括免费服务和付费服务，我们提供的任何免费功能和服务不应视为铂诺客放弃后续对该功能、服务进行收费的权利。付费功能/服务的具体价格及权益，以相关服务购买开通页面所展示的内容为准。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">6.2.</span></strong><strong><span
            style="font-size: 14px;">您理解并同意，我们可能会根据业务发展、市场环境等情况，对【巡检GO】产品和服务的收费模式、标准和价格进行修改和调整，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-family: 宋体, SimSun; font-size: 14px;">6.3.</span></strong><strong><span
          style="font-size: 14px; font-family: 宋体, SimSun; background-position: initial; background-color: white;">一旦您购买开通相关服务支付完成后，不可进行转让和退款。除非因【巡检GO】技术原因，导致您购买时被重复扣款或因产品技术原因导致您无法享有购买的服务时，您可提出退款申诉，经过我们核实后可为您办理退款，但您需要提供相应信息并配合我们处理。</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun; font-size: 14px;">6.4.您应当自行支付使用本服务可能产生的上网费以及其他第三方收取的通讯费、信息费等。</span><br></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph;line-height:150%">
      <strong><span style="font-size:14px;line-height:150%;font-family:宋体,SimSun;">七、特别授权</span></strong></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;font-weight:normal">7.1.</span></strong><strong><span
          style="font-size:14px;font-family:宋体,SimSun;font-weight: normal">当您向铂诺客作出任何形式的承诺，且相关公司已确认您违反了该承诺，则铂诺客有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，铂诺客无须就相关确认与您核对事实，或另行征得您的同意，且铂诺客无须就此限制措施或公示行为向您承担任何的责任。&nbsp;</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;font-weight:normal">7.2.</span></strong><strong><span
          style="font-size:14px;font-family:宋体,SimSun;font-weight: normal">一旦您违反本协议或与铂诺客签订的其他协议的约定，铂诺客有权力对您的权益采取限制措施，包括但不限于要求中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">八、个人信息和隐私保护</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">8.1.我们致力于保护您的个人信息和隐私，在您使用【巡检GO】服务时，您同意铂诺客按照在网站或客户端公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。我们希望通过隐私权政策向您清楚地介绍【巡检GO】对您个人信息的处理方式，因此建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">九、Teambition知识产权</span></strong></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">9.1.您了解及同意，除非铂诺客另行声明，本协议项下包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档，但您自行上传、下载、保存、制作、复制、分享的数据除外）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归铂诺客或其关联公司所有。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">9.2.您应保证，除非取得铂诺客书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">9.3.铂诺客享有对【巡检GO】的Logo、“【巡检GO】”等文字、图形及其组合，以及【巡检GO】其他标识、徽记、【巡检GO】的名称等的相关权利。未经铂诺客书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">9.4.铂诺客所有的产品、服务、技术与所有应用程序或其组件/功能/名称的知识产权均归属于铂诺客所有或归其权利人所有。&nbsp;</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">十、</span></strong><strong><span
            style="font-size: 14px; color: black;">第三方应用及服务</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph;font-variant-ligatures: normal;font-variant-caps: normal;orphans: 2;widows: 2;-webkit-text-stroke-width: 0px;word-spacing:0px">
      <span
        style="font-size: 14px; color: black; font-family: 宋体, SimSun;">10.1.【巡检GO】允许第三方应用接入【巡检GO】开放平台以便您选购开通、管理和使用<strong>第三方应用</strong>。您了解并同意，【巡检GO】仅作为平台提供者，钉钉仅为了您便利操作而提供该功能模块或服务订购和/或使用入口，相关应用由该第三方独立提供，除法律法规和本协议另有规定外，铂诺客不对您对该应用的使用承担任何责任。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph;font-variant-ligatures: normal;font-variant-caps: normal;orphans: 2;widows: 2;-webkit-text-stroke-width: 0px;word-spacing:0px">
      <span
        style="font-size: 14px; color: black; font-family: 宋体, SimSun;">10.2.您理解并同意，您在使用【巡检GO】服务过程中的第三方应用及服务时，除遵守本协议的约定外，还应遵守第三方用户协议。<strong>您在选择使用第三方服务前应充分了解第三方服务的产品功能、服务协议及隐私保护政策，再选择是否开通功能并</strong><strong>遵守第三方用户协议。</strong></span>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><span
        style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">十一、服务中止或终止</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:0;margin-left:0;margin-bottom:0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-weight: normal;">11.2</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;">．您了解并同意，铂诺客可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内中止，铂诺客无需承担除法律有明确规定外的责任，但应事先在【巡检GO】官网以公示的形式通告您。</span></strong></span>
    </p>
    <p style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0"><span
        style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">11.3.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">如存在下列违约情形之一，铂诺客有权立即对您中止或终止服务，并要求您赔偿损失：</span></strong></span>
    </p>
    <p style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 28px"><span
        style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">1)</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">用户违反第四条之注册义务；</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">2)</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">用户使用收费网络服务时未按规定支付相应服务费；</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 28px;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">3)</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">用户违反第五条服务使用规范之规定。</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:0;margin-left:0;margin-bottom:0;text-align:justify;text-justify:inter-ideograph">
      <strong style="background-color: initial; font-size: 14px;"><span
          style="font-family: 宋体, SimSun;">十二、有限责任</span></strong><br></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-weight: normal;">12.1.</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;">所有产品和服务将按"现状"和按"可得到"的状态提供。铂诺客在此明确声明对产品和服务不作任何明示或暗示的保证，包括但不限于对产品和服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.2.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">铂诺客对产品和服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-weight: normal;">12.3.</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;">不论在何种情况下，铂诺客均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担除法律有明确规定外的责任。</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">不论是否可以预见，不论是源于何种形式的行为，铂诺客不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或附随的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担除法律有明确规定外的责任：</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.1.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">使用或不能使用产品和服务；</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.2.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">通过产品和服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.3.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">未经授权的存取或修改数据或数据的传输；</span></strong></span>
    </p>
    <p
      style="margin-top:3px;margin-right:0;margin-bottom:3px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.4.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">第三方通过产品和服务所作的陈述或行为；</span></strong></span>
    </p>
    <p
      style="margin-top:3px;margin-right:0;margin-bottom:3px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">12.4.5.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">其它与产品和服务相关事件，包括疏忽等，所造成的损害。</span></strong></span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">12.5.</span></strong><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">您充分了解并同意，鉴于互联网环境的特殊性，您在产品和服务中分享的信息有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，铂诺客对此不承担任何除法律有明确规定外的责任。</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <strong><span style="font-size:14px;font-family:宋体,SimSun;">12.6.</span></strong><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">您了解并同意，在使用产品和服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于产品和服务使用规范所述，该等风险应由您自行承担，铂诺客对此不承担任何除法律有明确规定外的责任。</span></strong>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px;">十三、违约责任及赔偿</span></strong><strong><span
            style="font-size: 14px;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-size: 14px; font-family: 宋体, SimSun;">13.1.如果我们发现或收到他人举报您有违反本协议约定的，铂诺客有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用【巡检GO】账号及服务，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">13.2.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">您同意，由于您通过【巡检GO】上载、传送、制作、复制、分享的任何信息、使用本产品和服务其他功能、违反本协议或您侵害他人任何权利（包括但不限于知识产权、肖像权、名誉权等）因而衍生或导致任何第三人向铂诺客及其关联公司提出任何索赔或请求，或铂诺客及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于合理律师费），保证铂诺客不受任何损失。</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span style="font-size: 14px;">十四、通知</span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-weight: normal;">14.1.</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;">铂诺客向您发出的任何通知，可采用电子邮件、网站公告、手机短信等方式，且该等通知应自相关信息发出之日起视为已向用户送达或生效。</span></strong><strong><span
            style="font-size: 14px; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">14.2.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">您向铂诺客发出的任何通知应发至铂诺客对外正式公布或以下述第十六条所约定的方式告知用户的联系方式或使用其他铂诺客认可的其他通知方式进行送达。</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun; font-weight: normal;">14.3.</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">我们可能通过您注册时填写的手机号码向您推送您可能需要的服务、功能或活动等商业性信息。但请您放心，您如果不愿意接收此类信息，您可以通过手机短信中提供的退订方式进行退订，退订后您将不再收到此类信息。</span></strong><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;"></span></strong></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun;"><strong><span
            style="font-size: 14px; font-family: 宋体, SimSun;">十五、争议解决及其他</span></strong></span></p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><span
        style="font-size: 14px; font-family: 宋体, SimSun;"></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-family: 宋体, SimSun; font-size: 14px;">15.1.本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决，协商不成时，任何一方均可向被告一方所在地有管辖权的人民法院提起诉讼。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun; font-size: 14px;">15.2.如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-family: 宋体, SimSun; font-size: 14px;">15.3.铂诺客于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。</span>
    </p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span style="font-family: 宋体, SimSun; font-size: 14px;">15.4.本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。</span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph">
      <span
        style="font-family: 宋体, SimSun; font-size: 14px;">15.5.您理解并同意，铂诺客有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知。</span>
    </p>
    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong
        style="background-color: initial; font-family: 宋体, SimSun;"><span
          style="font-size: 14px;">十六、联系方式</span></strong><br><span
        style="font-size: 14px; font-family: 宋体, SimSun;"></span></p>
    <p
      style="margin-top:8px;margin-right:0;margin-bottom:8px;margin-left: 0;text-align:justify;text-justify:inter-ideograph;text-indent:28px">
      <span style="font-size: 14px; font-family: 宋体, SimSun;">您对本协议有任何疑问或意见，可以通过发送邮件至：</span><a
        href="mailto:support@yoojet.com"
        style="font-size: 14px; font-family: 宋体, SimSun; text-decoration: underline;"><span
          style="font-size: 14px; font-family: 宋体, SimSun;">info@prcchina.com.cn</span></a><span
        style="font-family: 宋体, SimSun;"><span style="font-family: 宋体, SimSun; font-size: 14px;">或电话拨打</span><span
          style="font-size: 14px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;, 宋体, SimSun, 黑体, SimHei;">+86-10-6332
          9088与我们联系</span><span style="font-family: 宋体, SimSun; font-size: 14px;">与我们联系。</span></span></p>


    <p style=";margin-bottom:0;text-align:justify;text-justify: inter-ideograph"><strong><span
          style="font-size:14px;font-family:宋体,SimSun;">&nbsp;</span></strong></p>
  </div>
</template>
<script>
// 修改标题
document.title = "巡检GO";
</script>